<template>
  <div>
    <h3 class="font-weight-bold">New Deposit</h3>
    <b-row>
      <b-col cols="7">
        <b-form class="w-100" @submit.prevent="savePub">
          <b-form-group label="Publication Title">
            <b-form-input
              v-model="form.publication_title"
              class="deposit-control"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Original Title">
            <b-form-input
              v-model="form.original_title"
              required
              class="deposit-control"
            ></b-form-input>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Publication Type">
                <b-form-select v-model="form.pub_type" :options="options"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="ISSN" v-if="isMagazine">
                <b-form-input
                  v-model="form.issn"
                  required
                  class="deposit-control"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="ISBN" v-else>
                <b-form-input
                  v-model="form.isbn"
                  required
                  class="deposit-control"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6" v-if="isMagazine">
              <b-form-group label="Issue Date">
                <b-form-datepicker
                  v-model="form.issue_date"
                  class="deposit-control"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  required
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-else>
              <b-form-group label="Publication Date">
                <b-form-datepicker
                  v-model="form.publication_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  class="deposit-control"
                  required
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Author">
                <v-select
                  :options="authors"
                  taggable
                  :reduce="(author) => author.id"
                  :create-option="(name) => ({ name, id: null })"
                  label="name"
                  v-model="form.author"
                  @option:created="addAuthor"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.author"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Publisher">
                <v-select
                  :options="publishers"
                  taggable
                  label="name"
                  :reduce="(publisher) => publisher.id"
                  :getOptionKey="(option)=>option.id"
                  v-model="form.publisher"
                  :create-option="(name) => ({ name, id: name })"
                  @option:created="addPublisher"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.publisher"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Number of copies">
                <b-form-input
                  v-model="form.frequency"
                  class="deposit-control"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-form-group label="Submission Type">
                <b-form-select v-model="form.sub_type" :options="subOptions"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="7" align-self="center">
              <b-form-group class="mt-4 pt-1">
                <b-form-file
                  placeholder="Choose a file to Upload"
                  drop-placeholder="Drop file here..."
                  v-model="form.file"
                  :accept="mimeType"
                  class="deposit-control"
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button variant="danger" class="mt-1 w-100 mx-auto" type="submit"
                >ADD PUBLICATION</b-button>
        </b-form>
      </b-col>
      <b-col cols="5">
        <b-card>
          <h5 class="font-weight-bold text-center">ADDED PUBLICATIONS</h5>
          <b-card-body style="min-height: 20em" class="px-0">
            <b-list-group>
              <transition-group name="list">
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-for="pub in publications"
                  :key="pub.idx"
                >
                  {{ pub.publication_title | fmt_title}}
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    @click="dropPub(pub.idx)"
                  >
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </b-list-group-item>
              </transition-group>
            </b-list-group>
          </b-card-body>
          <b-button
            variant="danger"
            class="w-100"
            :disabled="!publications.length"
            @click="saveDeposit"
            >
                <b-spinner small v-show="saving"></b-spinner>
                SAVE DEPOSIT
            </b-button
          >
        </b-card>
      </b-col>
    </b-row>
    <new-publisher :name="newpub" @new-publ="setPublisher"></new-publisher>
    <new-author :name="newauthr" @new-athr="setAuthor"></new-author>
  </div>
</template>

<script>
import { createApi } from "../applications/api";
import NewAuthor from '../authors/NewAuthor.vue';
import NewPublisher from "../publishers/NewPublisher.vue";
const api = createApi("deposits");
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
export default {
  components: { NewPublisher,NewAuthor },
  filters:{
    /**
     * 
     * @param {string} title 
     */
    fmt_title(title){
      return title.length>20?title.substring(0,20)+'..':title;
    }
  },
  computed: {
    publishers() {
      return this.$store.getters["newapplication/getFullPublishers"];
    },
    authors() {
      return this.$store.getters["newapplication/getFullAuthors"];
    },
    mimeType(){
      return this.form.sub_type=='govt'?'application/pdf':'image/*'
    },
    isMagazine(){
      return this.form.pub_type=='serial/periodicals';
    },
    isOther(){
      return this.form.pub_type=='other';
    }
  },
  data() {
    return {
      publications: [],
      saving:false,
      newpub: "",
      newauthr:'',
      options:[
        { value: 'serial/periodicals', text: 'Magazines,Journals &  Newspapers' },
        {value:'books',text:'Book'},
        { value: 'other', text: 'Other' }
      ],
      subOptions:[
        {value:'govt',text:"Government Doc"},
        {value:'other',text:'Other'}
      ],
      form: {
        author: "",
        publisher: "",
        publication_title: "",
        file: null,
        original_title: "",
        publication_date: "",
        issue_date: "",
        isbn: "",
        issn: "",
        frequency: "",
        pub_type:'magazine',
        sub_type:'govt',
        idx:null
      },
    };
  },
  methods: {
    savePub() { 
      const idx = (Math.random() + 1).toString(36).substring(7);
      this.form.idx = idx;
      this.publications = [this.form, ...this.publications];
      this.reset();
    },
    dropPub(idx) {
      this.publications = this.publications.filter((el) => el.idx != idx);
    },
    async convertPubs() {
      const files = await Promise.all(
        this.publications.map((pub) => toBase64(pub.file))
      );
      const publications = files.map((el, idx) => ({
        ...this.publications[idx],
        file: el,
      }));
      return publications;
    },
    async saveDeposit() {
      const publications = await this.convertPubs();
      this.saving = true;
      const appl = await api.createDeposit(publications);
      this.saving = false;
      this.$store.dispatch("deposit/insert", appl).then(() => {
        this.$router.push({ path: "/deposits" });
      });
    },
    reset() {
      this.form = {
        author: "",
        publisher: "",
        publication_title: "",
        file: null,
        original_title: "",
        publication_date: "",
        issue_date: "",
        isbn: "",
        issn: "",
        frequency: "",
        pub_type:'magazine',
        sub_type:'govt',
        idx:null
      };
    },
    setPublisher(pub) {
      this.form.publisher = pub.id;
    },
    addPublisher({ name }) {
      this.newpub = name;
      this.$bvModal.show("new-publisher");
    },
    addAuthor({name}){
        this.newauthr = name;
        this.$bvModal.show("new-author");
    },
    setAuthor({id}){
        this.form.author = id;
    }
  },
};
</script>

<style>
.font-weight-medium {
  font-weight: 500;
}
.deposit-control:focus {
  border-color: var(--dash-red-pale) !important;
  border-width: 2px;
  box-shadow: none;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}
</style>